import React from 'react'
import {Route} from 'react-router'
import pMinDelay from 'p-min-delay';
import Loading from './../component/Common/loader';
import loadable from './../component/Common/loader/loadable';
const ComingSoon = loadable(() => pMinDelay(import ('../page/coming-soon'), 250), { fallback: "..."});



const PublicRoute = ({component, path, ...rest}) => {

    return(
    
    <Route path={path} component={component}/>
    // <Route path={'/'} component={ComingSoon}/>
)};


export default PublicRoute;