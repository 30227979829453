import * as ActionTypes from '../action-types'
import Swal from "sweetalert2";

const initialState = {
    products:[],
    carts: [],
    favorites: [],
    compare: [],
    single:null,
};

const Products = (state= initialState,{type,payload = null}) => {
    switch(type){
        case ActionTypes.PROFILE_T:
            return profile(state,payload);
        case ActionTypes.PRODUCT_ID:
            return getProductById(state,payload);
        case ActionTypes.ADD_CART:
            return addToCart(state,payload);
        case ActionTypes.ADD_COMP:
            return addToComp(state);
        case ActionTypes.UPDATE_CART:
            return updateCart(state,payload);
        case ActionTypes.REMOVE_CART:
            return removeCart(state,payload);
        case ActionTypes.DEL_COMPARE:
            return delCompare(state,payload);
        case ActionTypes.CLEAR_CART:
            return clearCart(state,payload);
        case ActionTypes.ADD_FAV:
            return addToFav(state,payload);
        case ActionTypes.REMOVE_FAV:
            return removeFav(state,payload);
        case ActionTypes.SPECIALIST_TYPE:
            return splid(state,payload);
        case ActionTypes.COM_OR_PAR:
            return copar(state,payload);
        default:
            return state;
    }
};

const copar = (state,payload) => {
    state = Object.assign({}, state, {__copar: payload}); 
    return state;
};

const splid = (state,payload) => {
    state = Object.assign({}, state, {__splid: payload}); 
    return state;
};

const profile = (state,payload) => {
    state = Object.assign({}, state, payload);
    return state;

};
// Get Single Product
const getProductById = (state, action) => {
    let { id } = action.payload;
    let arr = state.products.find(item => item.id === parseInt(id))
    state.single = arr
}
// Add to Cart
const addToCart = (state, action) =>{

    let { id } = action.payload;

    // Check existance
    let item = state.carts.find(i => i.id === parseInt(id))
    if (item === undefined) {
        // Get Product
        let arr = state.products.find(item => item.id === parseInt(id))
        arr.quantity = 1
        state.carts.push(arr)
        Swal.fire({
            title: 'Success!',
            text: 'Successfully added to your Cart',
            icon: 'success',
            showConfirmButton: false,
            timer: 2500
          })

    }else{
        Swal.fire({
            title: 'Failed!',
            text: 'This product is already added in your Cart',
            imageUrl: item.img,
            imageWidth: 200,
            imageAlt: item.title,
            showConfirmButton: false,
            timer: 5000
          })
      }
}
// Add to Compare
const addToComp = (state, action) =>{
    if (state.compare.length >= 3) {
        Swal.fire({
            title: 'Failed!',
            text: 'Compare List is Full',
            icon: 'warning',
            showConfirmButton: false,
            timer: 2500,
          })
        return;
    }

    let { id } = action.payload;

    // Check existance
    let item = state.compare.find(i => i.id === parseInt(id))
    if (item === undefined) {
        // Get Product
        let arr = state.products.find(item => item.id === parseInt(id))
        state.compare.push(arr)
        Swal.fire({
            title: 'Success!',
            text: 'Successfully added to Compare List',
            icon: 'success',
            showConfirmButton: false,
            timer: 2500,
          })
    }else{
            Swal.fire({
                title: 'Failed!',
                text: 'Already Added in Compare List',
                imageUrl: item.img,
                imageWidth: 200,
                imageAlt: item.title,
                showConfirmButton: false,
                timer: 5000,
            })
      }
}
// Update Cart
const updateCart = (state, action) =>{
    let { val, id } = action.payload;
    state.carts.forEach(item => {
        if(item.id === parseInt(id)){
            item.quantity = val
        }
    })

}
// Remove Cart
const removeCart = (state, action) =>{
    let { id } = action.payload;
    let arr = state.carts.filter(item => item.id !== parseInt(id))
    state.carts = arr
    
}
// Delete from Compare
const delCompare = (state, action) =>{
    let { id } = action.payload;
    let arr = state.compare.filter(item => item.id !== parseInt(id))
    state.compare = arr
    
}
// Clear Cart
const clearCart = (state) =>{
    state.carts = []
}
// Add to Favorite / Wishlist
const addToFav = (state, action) =>{
    let { id } = action.payload;

    // Check existance
    let item = state.favorites.find(i => i.id === parseInt(id))
    if (item === undefined) {
        // Get Product
        let arr = state.products.find(item => item.id === parseInt(id))
        arr.quantity = 1
        state.favorites.push(arr)
        Swal.fire('Success', "Added to Wishlist", 'success') 
    }else{
          Swal.fire('Failed', "Already Added in Wishlist", 'warning')
      }
}
// Remove from Favorite / Wishlist
const removeFav = (state, action) =>{
    let { id } = action.payload;
    let arr = state.favorites.filter(item => item.id !== id)
    state.favorites = arr
    
}

export default Products;
