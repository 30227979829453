import React from 'react';
import {BrowserRouter as Router,
    Switch
} from 'react-router-dom';

import routes from './routes'
import PublicRoute from './Public'
import PrivateRoute from './Private'

import loadable from './../component/Common/loader/loadable';
// import Loading from './../component/Common/loader/Loader2';
import Loading from './../component/Common/loader';
import pMinDelay from 'p-min-delay';

const ScrollToTop = loadable(() => pMinDelay(import ('../component/Common/ScrollToTop'), 250), { fallback: <Loading />});

// const isAuthenticated = localStorage.getItem('isAuthenticated');
// const isVerify_t = localStorage.getItem('isVerify');
const Routes = () => (
    <Router>
            <ScrollToTop />
            <Switch>
                {routes.map((route,i) => {
                    if(route.auth){ 
                        return <PrivateRoute 
                                    // isVerify_t={isVerify_t} 
                                    // isAuthenticated={isAuthenticated} 
                                    key={i} 
                                    // isVerify={isVerify_t}
                                    path={route.path} 
                                    component={route.component} 
                                    {...route}
                                />
                    }else{
                        return <PublicRoute key={i} path={route.path} component={route.component} {...route}/>
                    }
                })}
            </Switch>
    </Router>
);

export default Routes;