import React from 'react'
import img from '../../../assets/img/ecom-cart.gif'
const Loader = () => {
    return (
        <>
            <div id="preloader" style={{display: 'block'}}>            
                <div className="preloader-inner">
                <i className="preloader-icon thm-clr flaticon-brainstorm" />
                </div>
            </div>
        </>
    )
}

export default Loader 