export const INITSTATE_T = 'INITSTATE_T';
export const AUTH_LOGIN = 'AUTH_LOGIN';
export const AUTH_REGISTER = 'AUTH_REGISTER';
export const PROMO_STATUS = 'PROMO_STATUS';
export const PROMO_CENTER = 'PROMO_CENTER';
export const STOP_COOKIES = 'STOP_COOKIES';
export const COOKIE = 'COOKIE';
export const STOP_PROMO = 'STOP_PROMO';
export const AUTH_CHECK = 'AUTH_CHECK';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const AUTH_VERIFY = 'AUTH_VERIFY';
export const PROFILE_T = 'PROFILE_T';
export const PRODUCT_ID = 'PRODUCT_ID';
export const ADD_CART = 'ADD_CART';
export const ADD_COMP = 'ADD_COMP';
export const UPDATE_CART = 'UPDATE_CART';
export const REMOVE_CART = 'REMOVE_CART';
export const DEL_COMPARE = 'DEL_COMPARE';
export const CLEAR_CART = 'CLEAR_CART';
export const ADD_FAV = 'ADD_FAV';
export const REMOVE_FAV = 'REMOVE_FAV';
export const SPECIALIST_TYPE = 'SPECIALIST_TYPE';
export const COM_OR_PAR = 'COM_OR_PAR';
export const SPAGE_T = 'SPAGE_T';
export const CPAGE_T = 'CPAGE_T';
export const PBTNHIDEN = 'PBTNHIDEN';
export const SBTNHIDEN = 'SBTNHIDEN';
export const CATSNUM = 'CATSNUM';
export const RESET_CART = 'RESET_CART';
export const CATVIL = 'CATVIL';
export const COUNTRY_CITY = 'COUNTRY_CITY';
export const CATNAME = 'CATNAME';
export const CITYOBJ = 'CITYOBJ';
export const PAYMENTID = 'PAYMENTID';
export const MAILSEND = 'MAILSEND';
export const UINFS = 'UINFS';
export const STARVAL = 'STARVAL';
export const APPClkREGISTER = 'APPClkREGISTER';
export const VERIFYACCOUNT = 'VERIFYACCOUNT';
export const RNOTIFICATION = 'RNOTIFICATION';
export const STARVALRN = 'STARVALRN';
