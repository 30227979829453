import { applyMiddleware,compose, createStore} from 'redux'
// import { applyMiddleware,compose, legacy_createStore as createStore} from 'redux'
import RootReducer from './reducers'
import ReduxThunk from 'redux-thunk'
import {persistStore, persistReducer} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
// import hardSet from 'redux-persist/lib/stateReconciler/hardSet'
import hardSet from 'redux-persist/lib/stateReconciler/autoMergeLevel2';

const persistConfig = {
    key: 'root',
    storage,
    stateReconciler: hardSet,
}

const persistedReducer = persistReducer(persistConfig, RootReducer);
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
    persistedReducer,
    composeEnhancers(
        applyMiddleware(ReduxThunk)
    )
);
    persistStore(store);
    export default store;
