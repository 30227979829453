import axios from 'axios'
import store from './store'
import * as actions from './store/actions'

 axios.defaults.baseURL = 'https://apiservices.tootreecorp.com/v1';
// axios.defaults.baseURL = 'http://192.168.100.122:8088/v1';
// axios.defaults.baseURL = 'http://192.168.100.242:8088/v1';
//axios.defaults.baseURL = 'http://192.168.100.122:8088/v1';

axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';

axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('jwt_token')}`;

axios.interceptors.response.use(
  response => response,
  (error) => {
      if(error.response.status === 401 ) {
          store.dispatch(actions.logout())
          window.location.href = `/login`;
      }
      return Promise.reject(error);
  }
);

// if (localStorage.getItem('mso') && localStorage.getItem('mso') === true) {
//     axios.interceptors.response.use(
//     response => response,
//     (error) => {
//         if(error.response.status === 401 ) {
//             return axios
//         .post("/clients/web/admin/refresh/", {
//           refresh_token: localStorage.getItem('refresh_token'),
//         })
//         .then((res) => {
//           store.dispatch(actions.login(res.data));
//         }) .catch((error) => {
//           store.dispatch(actions.login({access_token: "", refresh_token: "" }));

//           store.dispatch(actions.logout())
//           Promise.reject(error);
//         });

//         }
//         return Promise.reject(error);
//     }
//   );
// } else {

//     axios.interceptors.response.use(
//     response => response,
//     (error) => {
//         if(error.response.status === 401 ) {
//             store.dispatch(actions.logout())
//             window.location.reload(); 
//         }
//         return Promise.reject(error);
//     }
//   );
// }


export default axios;
