import React from 'react'
import {Route, Redirect} from 'react-router'
import {connect} from 'react-redux'

const isAuthenticatedStorage = localStorage.getItem('isAuthenticated');
const isVerifyStorage = localStorage.getItem('isVerify');


const PrivateRoute = ({component: Component, isAuthenticated, isVerify, ...rest}) => {

  let isAuthenticatedRedux = Boolean(isAuthenticated);
  let isVerifyRedux = Boolean(isVerify);

  console.log("lll ", isVerify);

    return (
      <Route
        {...rest}
        render={(props) => isAuthenticated === 'true'
          ? 
          isVerify === 'true' 
              ? 
                <Component {...props} /> 
              : 
                <Redirect to={{pathname: '/activate-your-account', state: {from: props.location}}} />

          : 
            <Redirect to={{pathname: '/login', state: {from: props.location}}} />}
      />
    )
  }


  const mapStateToProps = (state) => { 
    return {
        isAuthenticated : !isAuthenticatedStorage ? state.user.isAuthenticated : isAuthenticatedStorage,
        isVerify : !isVerifyStorage ? state.user.isVerify : isVerifyStorage,
        status : state.user.status,
        user: state.user.user
    }
};

export default connect(mapStateToProps)(PrivateRoute);