import * as ActionTypes from '../action-types'
import Http from '../../Http'

const initialState = {
    isAuthenticated : 'false',
    isVerify: 'false',
    status: false,
    user: {}
};

const Auth = (state= initialState,{type,payload = null}) => {
    switch(type){
        case ActionTypes.AUTH_LOGIN:
            return login(state,payload);
        case ActionTypes.AUTH_REGISTER:
            return register(state,payload);
        case ActionTypes.AUTH_LOGOUT:
            return logout(state);
        case ActionTypes.VERIFYACCOUNT:
            return verifyAccount(state,payload);
        default:
            return state;
    }
};


const login = (state,payload) => {
    const jwtToken = payload.access_token;
    const expiresIn = payload.expires_in;
    const refreshToken = payload.refresh_token;
    const token_type = payload.token_type;
    
    localStorage.setItem('jwt_token',jwtToken);
    localStorage.setItem('refresh_token',refreshToken);
    // localStorage.setItem('isAuthenticated',true);
    
    // localStorage.setItem('isVerify', isVerify === 0 ? false : true);
    // localStorage.setItem('isVerify',isVerify);
    Http.defaults.headers.common['Authorization'] = `${token_type} ${jwtToken}`;
    state = Object.assign({}, state, {
        isAuthenticated: 'true',
        isVerify: 'false',
        status: true
    });
    return state;
};

const verifyAccount = (state,payload) => {
    const isVerify = payload.is_activate;
    
    localStorage.setItem('isAuthenticated','true');
    
    localStorage.setItem('isVerify', isVerify === 0 ? 'false' : 'true');
    
    state = Object.assign({}, state, {
        isAuthenticated: 'true',
        isVerify: isVerify === 0 ? 'false' : 'true',
        status: true,
        __rd: payload.real_id
    });
    return state;
};

const register = (state,payload) => {
    state = Object.assign({}, state, {user: payload});
    return state;
};

const logout = (state) => {
    
    localStorage.removeItem('jwt_token_user');
    localStorage.removeItem('isAuthenticated');
    localStorage.removeItem('isVerify');
    state = Object.assign({},state,{
        isAuthenticated: false,
        isVerify: false,
        status: false,
        user: {}
    });
    return state;
};

const profile = (state,payload) => {
    state = Object.assign({}, state, payload);
    return state;

};

export default Auth;
