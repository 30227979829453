import React from 'react';
import ReactDOM from 'react-dom';
import 'font-awesome/css/font-awesome.min.css'
import MessengerCustomerChat from 'react-messenger-customer-chat';

import App from './App';

// import { store } from './app/store';
import "./app/i18n";
import  store  from './store';
import { Provider } from 'react-redux';

// import Custom Css
import "assets/css/all.min.css"
import "assets/css/style.css"
import "assets/css/color.css"
import "assets/css/responsive.css"
import "assets/css/animate.min.css"
import "assets/css/flaticon.css" 
import "assets/css/_resets.app.css"
import "assets/css/Footer.css"
import "assets/css/HomeMobil.css"
// import "./assets/css/Home.css"

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      {/* <MessengerCustomerChat pageId="383273655072064" appId="940218140171982" /> */}
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root') 
);
