import * as ActionTypes from '../action-types'

export function initialStatManual(payload) {
    return {
        type: ActionTypes.INITSTATE_T,
        payload
    }
}

export function login(payload) {
    return {
        type: ActionTypes.AUTH_LOGIN,
        payload
    }
}

export function register(payload) {
    return {
        type: ActionTypes.AUTH_REGISTER,
        payload
    }
}

export function stopPromo() {
    return {
        type: ActionTypes.STOP_PROMO
    }
}

export function promoStatus() {
    return {
        type: ActionTypes.PROMO_STATUS
    }
}

export function promoCenter() {
    return {
        type: ActionTypes.PROMO_CENTER
    }
}

export function stopCookie() {
    return {
        type: ActionTypes.STOP_COOKIES
    }
}

export function cookie() {
    return {
        type: ActionTypes.COOKIE
    }
}


export function logout() {
    return {
        type: ActionTypes.AUTH_LOGOUT
    }
}

export function authCheck() {
    return {
        type: ActionTypes.AUTH_CHECK
    }
}

export function authVerify(payload) {
    return {
        type: ActionTypes.AUTH_VERIFY,
        payload
    }
}

export function profile(payload) {
    return {
        type: ActionTypes.PROFILE_T,
        payload
    }
}

export function getProductById(payload) {
    return {
        type: ActionTypes.PRODUCT_ID,
        payload
    }
}

export function addToCart(payload) {
    return {
        type: ActionTypes.ADD_CART,
        payload
    }
}
export function addToComp(payload) {
    return {
        type: ActionTypes.ADD_COMP,
        payload
    }
}
export function updateCart(payload) {
    return {
        type: ActionTypes.UPDATE_CART,
        payload
    }
}
export function removeCart(payload) {
    return {
        type: ActionTypes.REMOVE_CART,
        payload
    }
}
export function delCompare(payload) {
    return {
        type: ActionTypes.DEL_COMPARE,
        payload
    }
}
export function clearCart(payload) {
    return {
        type: ActionTypes.CLEAR_CART,
        payload
    }
}
export function addToFav(payload) {
    return {
        type: ActionTypes.ADD_FAV,
        payload
    }
}
export function removeFav(payload) {
    return {
        type: ActionTypes.REMOVE_FAV,
        payload
    }
}
export function showCountryAndCity(payload) {
    return {
        type: ActionTypes.COUNTRY_CITY,
        payload
    }
}
export function saveCatName(payload) {
    return {
        type: ActionTypes.CATNAME,
        payload
    }
}

export function saveCityObj(payload) {
    return {
        type: ActionTypes.CITYOBJ,
        payload
    }
}

export function paymentId(payload) {
    return {
        type: ActionTypes.PAYMENTID,
        payload
    }
}
export function mailSend(payload) {
    return {
        type: ActionTypes.MAILSEND,
        payload
    }
}
export function verifyAccount(payload) {
    return {
        type: ActionTypes.VERIFYACCOUNT,
        payload
    }
}

export function rNotification(payload) {
    return {
        type: ActionTypes.RNOTIFICATION,
        payload
    }
}