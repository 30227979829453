import React from 'react';
import loadable from './../component/Common/loader/loadable';
import Loading from './../component/Common/loader';
import pMinDelay from 'p-min-delay';

// All Page Lazy Import 
const About = loadable(() => pMinDelay(import('../page/about'), 250), { fallback: <Loading /> });
const Reservation = loadable(() => pMinDelay(import('../page/vendor/reservation'), 250), { fallback: <Loading /> });
const SpecialistDetail = loadable(() => pMinDelay(import('page/specialists/specialist-detail'), 250), { fallback: <Loading /> });
const SpecialisteList = loadable(() => pMinDelay(import('page/specialists/specialiste-list'), 250), { fallback: <Loading /> });
const Appointment = loadable(() => pMinDelay(import('page/Home/appointment'), 250), { fallback: <Loading /> });
const Home = loadable(() => pMinDelay(import('../page/Home'), 250), { fallback: <Loading /> });
const Custumer = loadable(() => pMinDelay(import('../page/vendor/custumerPage'), 250), { fallback: <Loading /> });
const HomeSpecialist = loadable(() => pMinDelay(import('../page/specialists/index'), 250), { fallback: <Loading /> });
const Whoareyou = loadable(() => pMinDelay(import('page/Home/whoareyou'), 250), { fallback: <Loading /> });
const DashboardConsultation = loadable(() => pMinDelay(import('../page/vendor/customerConsultation'), 250), { fallback: <Loading /> });
const DashboardDocument = loadable(() => pMinDelay(import('../page/vendor/customerDocument'), 250), { fallback: <Loading /> });
const ParentCompte = loadable(() => pMinDelay(import('../page/vendor/parentCompte'), 250), { fallback: <Loading /> });
const SpecialistCompte = loadable(() => pMinDelay(import('../page/specialists/specialistCompte'), 250), { fallback: <Loading /> });

const EmailTemplateOnes = loadable(() => pMinDelay(import('../page/email/index'), 250), { fallback: <Loading /> });
const EmailTemplateTwos = loadable(() => pMinDelay(import('../page/email/email-template-two'), 250), { fallback: <Loading /> });
const EmailTemplateThrees = loadable(() => pMinDelay(import('../page/email/email-template-three'), 250), { fallback: <Loading /> });
const InvoiceOne = loadable(() => pMinDelay(import('../page/invoice/index'), 250), { fallback: <Loading /> });
const InvoiceTwo = loadable(() => pMinDelay(import('../page/invoice/invoice-two'), 250), { fallback: <Loading /> });
// const Login = loadable(() => pMinDelay(import ('../page/login'), 250), { fallback: <Loading />});
const Login = loadable(() => pMinDelay(import('../page/Authentification/Login/loginNew'), 250), { fallback: <Loading /> });
const LoginAns = loadable(() => pMinDelay(import('../page/Authentification/Login/login'), 250), { fallback: <Loading /> });
const RegisterAns = loadable(() => pMinDelay(import('../page/register'), 250), { fallback: <Loading /> });
const Register = loadable(() => pMinDelay(import('page/Authentification/Register'), 250), { fallback: <Loading /> });
const Error = loadable(() => pMinDelay(import('../page/error'), 250), { fallback: <Loading /> });
const ComingSoon = loadable(() => pMinDelay(import('../page/coming-soon'), 250), { fallback: <Loading /> });
const MollieRedirection = loadable(() => pMinDelay(import('../page/Home/MollieRedirection'), 250), { fallback: <Loading /> });
const PasswordReset = loadable(() => pMinDelay(import('../page/Home/PasswordReset'), 250), { fallback: <Loading /> });
const ForgotPassword = loadable(() => pMinDelay(import('../page/Home/ForgotPassword'), 250), { fallback: <Loading /> });
const AccountToBeChecked = loadable(() => pMinDelay(import('page/Home/AccountToBeChecked'), 250), { fallback: <Loading /> });
const AccountActivated = loadable(() => pMinDelay(import('page/Home/AccountActivated'), 250), { fallback: <Loading /> });
const Notification = loadable(() => pMinDelay(import('page/vendor/notification'), 250), { fallback: <Loading /> });


const routes = [
    {
        path: '/',
        exact: true,
        auth: false,
        verify: false,
        component: Home
    },
    {
        path: '/activate-your-account',
        exact: true,
        auth: false,
        verify: false,
        component: AccountToBeChecked
    },
    {
        path: '/activated-account/:id?',
        exact: true,
        auth: false,
        verify: false,
        component: AccountActivated
    },
    {
        path: '/specialist-detail/:id?',
        exact: true,
        auth: false,
        verify: false,
        component: SpecialistDetail
    },
    {
        path: '/reservation',
        exact: true,
        auth: false,
        verify: false,
        component: Reservation
    },
    {
        path: '/specialiste-list',
        exact: true,
        auth: false,
        verify: false,
        component: SpecialisteList
    },
    {
        path: '/specialist',
        exact: true,
        auth: false,
        verify: false,
        component: SpecialisteList
        // component: Specialiste
    },
    {
        path: '/appointment',
        exact: true,
        auth: false,
        verify: false,
        component: Appointment
    },
    {
        path: '/dashboard/home',
        exact: true,
        auth: true,
        verify: false,
        component: Custumer
    },
    {
        path: '/specialist-board/home',
        exact: true,
        auth: true,
        verify: false,
        component: HomeSpecialist
    },
    {
        path: '/whoareyou',
        exact: true,
        auth: false,
        verify: false,
        component: Whoareyou
    },
    {
        path: '/dashboard/myconsultations',
        exact: true,
        auth: true,
        verify: false,
        component: DashboardConsultation
    },
    {
        path: '/dashboard/notification',
        exact: true,
        auth: true,
        verify: false,
        component: Notification
    },
    {
        path: '/dashboard/document',
        exact: true,
        auth: false,
        verify: false,
        component: DashboardDocument
    },
    {
        path: '/dashboard/specialist-compte',
        exact: true,
        auth: false,
        verify: false,
        component: SpecialistCompte
    },
    {
        path: '/dashboard/parent-compte',
        exact: true,
        auth: true,
        verify: false,
        component: ParentCompte
    },
    {
        path: '/email-template-two',
        exact: true,
        auth: true,
        verify: false,
        component: EmailTemplateTwos
    },
    {
        path: '/email-template-three',
        exact: true,
        auth: true,
        verify: false,
        component: EmailTemplateThrees
    },
    {
        path: '/invoice-one',
        exact: true,
        auth: true,
        verify: false,
        component: InvoiceOne
    },
    {
        path: '/about',
        exact: true,
        auth: false,
        verify: false,
        component: About
    },
    {
        path: '/redirectUrl',
        exact: true,
        auth: false,
        verify: false,
        component: MollieRedirection
    },
    {
        path: '/password-reset/:id?/:id2?',
        exact: true,
        auth: false,
        verify: false,
        component: PasswordReset
    },
    {
        path: '/forgot-password',
        exact: true,
        auth: false,
        verify: false,
        component: ForgotPassword
    },
    {
        path: '/invoice-two',
        exact: true,
        auth: true,
        verify: false,
        component: InvoiceTwo
    },
    {
        path: '/loginAns1',
        exact: true,
        auth: false,
        verify: false,
        component: LoginAns
    },
    {
        path: '/login',
        exact: true,
        auth: false,
        verify: false,
        component: Login
    },
    {
        path: '/register',
        exact: true,
        auth: false,
        verify: false,
        component: Register
    },
    {
        path: '/registerAns',
        exact: true,
        auth: false,
        verify: false,
        component: RegisterAns
    },
    {
        path: '/coming-soon',
        exact: true,
        auth: true,
        verify: false,
        component: ComingSoon
    },
    {
        exact: true,
        auth: false,
        verify: false,
        component: Error
    }

];

export default routes;