import * as ActionTypes from '../action-types'
// import Http from '../../Http'

const initialState = {
    cookie: true,
    stopCookie: false,
    promoCenter: false,
    promoStatus: false,
    stopPromo: false,
    __pt: false,
    __cpt: false,
    // __pbh: false,
    // __cbh: false,
    __catsum: null,
    __catvil: null,
    __cc: null,
    __pmi: null,
    __aclickr: false,
    __pm: {}
    // __catname: null
};

const Settings = (state = initialState, { type, payload = null }) => {
    switch (type) {
        case ActionTypes.SPAGE_T:
            return spageT(state, payload);
        case ActionTypes.CPAGE_T:
            return cpageT(state, payload);
        case ActionTypes.PBTNHIDEN:
            return pbhidden(state, payload);
        case ActionTypes.SBTNHIDEN:
            return sbhidden(state, payload);
        case ActionTypes.STOP_PROMO:
            return stopPromo(state);
        case ActionTypes.PROMO_STATUS:
            return promoStatus(state);
        case ActionTypes.PROMO_CENTER:
            return promoCenter(state);
        case ActionTypes.STOP_COOKIES:
            return stopCookie(state);
        case ActionTypes.COOKIE:
            return cookie(state);
        case ActionTypes.CATSNUM:
            return catsnum(state, payload);
        case ActionTypes.CATVIL:
            return catvil(state, payload);
        case ActionTypes.RESET_CART:
            return resetRedicer(state);
        case ActionTypes.COUNTRY_CITY:
            return showCountryAndCity(state, payload);
        case ActionTypes.CATNAME:
            return saveCatName(state, payload);
        case ActionTypes.CITYOBJ:
            return saveCityObj(state, payload);
        case ActionTypes.PAYMENTID:
            return paymentId(state, payload);
        case ActionTypes.UINFS:
            return userInfos(state, payload);
        case ActionTypes.STARVAL:
            return starval(state, payload);
        case ActionTypes.APPClkREGISTER:
            return appclikregister(state, payload);
        case ActionTypes.MAILSEND:
            return mailSend(state, payload);
        case ActionTypes.RNOTIFICATION:
            return rNotification(state, payload);
        case ActionTypes.STARVALRN:
            return starvalRN(state, payload);
        default:
            return state;
    }
};

const catvil = (state, payload) => {
    state = Object.assign({}, state, { __catvil: payload });
    return state;
};

const catsnum = (state, payload) => {
    state = Object.assign({}, state, { __catsum: payload });
    return state;
};

const spageT = (state, payload) => {
    state = Object.assign({}, state, { __pt: payload });
    return state;
};

const cpageT = (state, payload) => {
    state = Object.assign({}, state, { __cpt: payload });
    return state;
};

const pbhidden = (state, payload) => {
    state = Object.assign({}, state, { __pbh: payload });
    return state;
};

const sbhidden = (state, payload) => {
    state = Object.assign({}, state, { __cbh: payload });
    return state;
};

// Promo Center Modal Stop
const stopPromo = (state) => {
    state.stopPromo = true
};

// Promo Modal first appare
const promoStatus = (state) => {
    state.promoStatus = true
};

// Show / hide
const promoCenter = (state) => {
    state.promoCenter = !state.promoCenter
};

// Bottom Cookie Stop
const stopCookie = (state) => {
    state.stopCookie = true
};

// Cookie Show / hide
const cookie = (state) => {
    state.cookie = !state.cookie
};

const resetRedicer = (state) => {
    state = Object.assign({}, state, initialState);
    return state;
}

const showCountryAndCity = (state, payload) => {
    state = Object.assign({}, state, { __cc: payload });
    return state;
}

const saveCatName = (state, payload) => {
    state = Object.assign({}, state, { __catname: payload });
    return state;
}
const saveCityObj = (state, payload) => {
    state = Object.assign({}, state, { __cNjobname: payload });
    return state;
}
const paymentId = (state, payload) => {
    state = Object.assign({}, state, { __pmi: payload });
    return state;
}

const mailSend = (state, payload) => {
    state = Object.assign({}, state, { __pm: payload });
    return state;
}

const rNotification = (state, payload) => {
    state = Object.assign({}, state, { __regNot: payload });
    return state;
}

const userInfos = (state, payload) => {
    state = Object.assign({}, state, { __uinfs: payload });
    return state;
};

const starval = (state, payload) => {
    state = Object.assign({}, state, { __starval: payload });
    return state;
};

const starvalRN = (state, payload) => {
    state = Object.assign({}, state, { __starvalRN: payload });
    return state;
};

const appclikregister = (state, payload) => {
    state = Object.assign({}, state, { __aclickr: payload });
    return state;
};


export default Settings;
